import { useState, React } from "react";
import { Link } from "react-router-dom";
import "./UserBarHome.css";
import Cookies from 'js-cookie';
import pp from "./Images/PPMenuHome.png"
import upGradeOff from "./Images/BotonupgradeOFF.png"
import upGradeOn from "./Images/BotonupgradeON.png"
import inicioON from "./Images/InicioON.png"
import inicioOFF from "./Images/InicioOFF.png"
import proyectosON from "./Images/ProyectosON.png"
import proyectosOFF from "./Images/ProyectosOFF.png"
import TemplatesON from "./Images/TemplatesON.png"
import TemplatesOFF from "./Images/TemplatesOFF.png"
import ConfiguracionON from "./Images/ConfiguracionON.png"
import ConfiguracionOFF from "./Images/ConfiguracionOFF.png"
import CerrarSesionON from "./Images/Menu-CerrarSesion-ON.png"
import CerrarSesionOFF from "./Images/Menu-CerrarSesion-OFF.png"

const UserBar = () => {
  const [hoveredU, setHoveredU] = useState(false);
  const [hoveredI, setHoveredI] = useState(false);
  const [hoveredP, setHoveredP] = useState(false);
  const [hoveredT, setHoveredT] = useState(false);
  const [hoveredC, setHoveredC] = useState(false);
  const [hoveredCS, setHoveredCS] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(!!Cookies.get('userData'));

  const handleMouseEnterU = () => {
    setHoveredU(true);
  };
  const handleMouseLeaveU = () => {
    setHoveredU(false);
  };

  const handleMouseEnterI = () => {
    setHoveredI(true);
  };
  const handleMouseLeaveI = () => {
    setHoveredI(false);
  };

  const handleMouseEnterP = () => {
    setHoveredP(true);
  };
  const handleMouseLeaveP = () => {
    setHoveredP(false);
  };

  const handleMouseEnterT = () => {
    setHoveredT(true);
  };
  const handleMouseLeaveT = () => {
    setHoveredT(false);
  };

  const handleMouseEnterC = () => {
    setHoveredC(true);
  };
  const handleMouseLeaveC = () => {
    setHoveredC(false);
  };
  const handleMouseEnterCS = () => {
    setHoveredCS(true);
  };
  const handleMouseLeaveCS = () => {
    setHoveredCS(false);
  };

  const handleLogout = () => {
    // Eliminar cookies y actualizar estado
    Cookies.remove('userData');
    Cookies.remove('projects');
    Cookies.remove('success');
    localStorage.clear();
    setIsLoggedIn(false);
    // window.location.reload();
  };

  return (
    <div className="containerUserBar">
      <img className= "userImage" src={pp} />
      <p>
        {Cookies.get('userData') && JSON.parse(Cookies.get('userData')).name}
      </p>
      <Link to="/" onMouseEnter={handleMouseEnterU} onMouseLeave={handleMouseLeaveU}>
        {<img src={hoveredU ? upGradeOn : upGradeOff} alt="UpGrade" />}
      </Link>
      <Link to="/" onMouseEnter={handleMouseEnterI} onMouseLeave={handleMouseLeaveI}>
        {<img src={hoveredI ? inicioON : inicioOFF} alt="Inicio" />}
      </Link>
      <Link to="/" onMouseEnter={handleMouseEnterP} onMouseLeave={handleMouseLeaveP}>
        {<img src={hoveredP ? proyectosON : proyectosOFF} alt="Proyectos" />}
      </Link>
      <Link to="/" onMouseEnter={handleMouseEnterT} onMouseLeave={handleMouseLeaveT}>
        {<img src={hoveredT ? TemplatesON : TemplatesOFF} alt="Templates" />}
      </Link>
      <Link to="/" onMouseEnter={handleMouseEnterC} onMouseLeave={handleMouseLeaveC}>
        {<img src={hoveredC ? ConfiguracionON  : ConfiguracionOFF} alt="Templates" />}
      </Link>
      <Link className="CerrarSesionImage"  to="/" onClick={handleLogout} onMouseEnter={handleMouseEnterCS} onMouseLeave={handleMouseLeaveCS}>
        {<img src={hoveredCS ? CerrarSesionON  : CerrarSesionOFF} alt="Templates" />}
      </Link>
    </div>
  );
};

export default UserBar;