import React, { useState } from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import "./SiginForm.css";
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import axios from "axios";
import botonIniciarSesionON from "./Images/botonIniciarSesionON.png"
import botonIniciarSesionOFF from "./Images/botonIniciarSesionOFF.png"
import Cookies from 'js-cookie';

const SiginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [keepLoggedIn, setKeepLoggedIn] = useState(false); // Estado para la casilla de mantener sesión iniciada
  const [hoveredI, setHoveredI] = useState(false);

  const handleMouseEnterI = () => {
    setHoveredI(true);
  };
  const handleMouseLeaveI = () => {
    setHoveredI(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    if (name === "email") {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setIsEmailValid(emailPattern.test(value));
    }
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShow) => !prevShow);
  };

  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.email || !formData.password) {
      setErrorMessage("Por favor, completa todos los campos.");
      return;
    }

    if (!isEmailValid) {
      setErrorMessage("El correo electrónico no es válido.");
      return;
    }

    // Si todo está bien, realiza la solicitud HTTP
    axios
      .post("https://editor.piknamic.com/api/login", formData)
      .then((res) => {
        if (res.data.success === true) {
          // Guardar en localStorage
          localStorage.setItem('userData', JSON.stringify(res.data.user));
          localStorage.setItem('projects', JSON.stringify(res.data.projects));


          Cookies.set('success', res.data.success);
          // Si se selecciona "Mantener sesión iniciada", establecer una fecha de expiración lejana
          const expirationOptions = keepLoggedIn ? { expires: 365 } : { expires: null };
          Cookies.set('userData', JSON.stringify(res.data.user), expirationOptions);
          Cookies.set('projects', JSON.stringify(res.data.projects), expirationOptions)

          // Guardar en localStorage
          localStorage.setItem('keepLoggedIn', keepLoggedIn);      
                  
          Cookies.set('keepLoggedIn', keepLoggedIn, expirationOptions);

          history.push("/home");
         
        }
      })
      .catch((err) => {
        console.log("Error al iniciar sesión", err);
        Cookies.remove('userData');
        Cookies.remove('projects');
        Cookies.remove('success');
        setErrorMessage("Error al iniciar sesión. Por favor, inténtalo de nuevo.");
      });
  };

  return (
    <div className="siginForm">
      <div className="form">
        {/* <div className="iniciar">
          <h1>Iniciar Sesión</h1>
          </div> */}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Correo"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          {!isEmailValid && <p className="error-message">Este campo debe ser un correo electrónico válido</p>}

          <div className="form-group">
            <div className="password-input">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                placeholder="Contraseña"
                value={formData.password}
                onChange={handleChange}
              />
              {showPassword ? (
                <EyeOffIcon className="eye-icon" onClick={handleTogglePassword} />
              ) : (
                <EyeIcon className="eye-icon" onClick={handleTogglePassword} />
              )}
            </div>
          </div>
          <div className="olvidado">
            <Link to="/passwordrecovery" style={{ color: '#fff', textDecoration: 'none' }}>
              He olvidado  mi contraseña
            </Link>
          </div>       

          <div className="checkbox-container">
            <label>
              <input
                type="checkbox"
                className="checkbox-input"
                checked={keepLoggedIn}
                onChange={(e) => setKeepLoggedIn(e.target.checked)}
                placeholder="Busca un proyecto"
              />
              Mantener mi sesión iniciada
            </label>
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <div className="submit-button">
            <button type="submit" onMouseEnter={handleMouseEnterI} onMouseLeave={handleMouseLeaveI}>
            {<img src={hoveredI ? botonIniciarSesionON: botonIniciarSesionOFF} alt="Inicio" />}
            </button>
          </div>
        </form>
        <div className="notienescuenta" style={{ color: '#fff', textDecoration: 'none' }}>
          ¿No tienes cuenta? {' '}
          <Link to="/register" className="black-text" style={{ color: '#fff', textDecoration: 'none' }}>
            Regístrate
          </Link>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SiginForm);