import React, { useState } from "react";
import "./ContainerBoxes.css";
import CajaBiblioteca3DOff from "./Cajas/CajaBiblioteca3DOff.png";
import CajaBiblioteca3DOn from "./Cajas/CajaBiblioteca3DOn.png";
import CajaBrandingOn from "./Cajas/CajaBranding_ON.png";
import CajaBrandingOff from "./Cajas/CajaBrandingOff.png";
import CajaCamaradinamicaOn from "./Cajas/CajaCamaradinamicaOn.png";
import CajaCamaradinamicaOff from "./Cajas/CajaCamaradinamicaOff.png";
import CajaIluminacionajustableOff from "./Cajas/CajaIluminacionajustableOff.png";
import CajaIluminacionajustableOn from "./Cajas/CajaIluminacionajustableOn.png";
import CajaOnlineOn from "./Cajas/Cajaonline_ON.png";
import CajaOnlineOff from "./Cajas/CajaOnlineOff.png";
import CajaPostprocesadoOn from "./Cajas/Cajapostprocesado_ON.png";
import CajaPostprocesadoOff from "./Cajas/CajaPostprocesadoOff.png";

const ContainerBoxes = () => {
  const [isMouseOverBiblioteca, setIsMouseOverBiblioteca] = useState(false);
  const [isMouseOverBranding, setIsMouseOverBranding] = useState(false);
  const [isMouseOverCamaradinamica, setIsMouseOverCamaradinamica] = useState(false);
  const [isMouseOverIluminacion, setIsMouseOverIluminacion] = useState(false);
  const [isMouseOverOnline, setIsMouseOverOnline] = useState(false); // Nuevo estado para la quinta imagen
  const [isMouseOverPostprocesado, setIsMouseOverPostprocesado] = useState(false); // Nuevo estado para la sexta imagen

  const handleMouseOverBiblioteca = () => {
    setIsMouseOverBiblioteca(true);
  };

  const handleMouseOutBiblioteca = () => {
    setIsMouseOverBiblioteca(false);
  };

  const handleMouseOverBranding = () => {
    setIsMouseOverBranding(true);
  };

  const handleMouseOutBranding = () => {
    setIsMouseOverBranding(false);
  };

  const handleMouseOverCamaradinamica = () => {
    setIsMouseOverCamaradinamica(true);
  };

  const handleMouseOutCamaradinamica = () => {
    setIsMouseOverCamaradinamica(false);
  };

  const handleMouseOverIluminacion = () => {
    setIsMouseOverIluminacion(true);
  };

  const handleMouseOutIluminacion = () => {
    setIsMouseOverIluminacion(false);
  };

  const handleMouseOverOnline = () => {
    setIsMouseOverOnline(true);
  };

  const handleMouseOutOnline = () => {
    setIsMouseOverOnline(false);
  };

  const handleMouseOverPostprocesado = () => {
    setIsMouseOverPostprocesado(true);
  };

  const handleMouseOutPostprocesado = () => {
    setIsMouseOverPostprocesado(false);
  };

  return (
    <div className="ContainerBoxes">
      <div>
        <img
          src={isMouseOverBiblioteca ? CajaBiblioteca3DOn : CajaBiblioteca3DOff}
          alt="CajaBiblioteca3D"
          onMouseOver={handleMouseOverBiblioteca}
          onMouseOut={handleMouseOutBiblioteca}
        />
        <img
          src={isMouseOverBranding ? CajaBrandingOn : CajaBrandingOff}
          alt="CajaBranding"
          onMouseOver={handleMouseOverBranding}
          onMouseOut={handleMouseOutBranding}
        />
        <img
          src={isMouseOverCamaradinamica ? CajaCamaradinamicaOn : CajaCamaradinamicaOff}
          alt="CajaCamaradinamica"
          onMouseOver={handleMouseOverCamaradinamica}
          onMouseOut={handleMouseOutCamaradinamica}
        />
        <div>
        <img
          src={isMouseOverIluminacion ? CajaIluminacionajustableOn : CajaIluminacionajustableOff}
          alt="CajaIluminacionajustable"
          onMouseOver={handleMouseOverIluminacion}
          onMouseOut={handleMouseOutIluminacion}
        />
        <img
          src={isMouseOverOnline ? CajaOnlineOn : CajaOnlineOff}
          alt="CajaOnline"
          onMouseOver={handleMouseOverOnline}
          onMouseOut={handleMouseOutOnline}
        />
        <img
          src={isMouseOverPostprocesado ? CajaPostprocesadoOn : CajaPostprocesadoOff}
          alt="CajaPostprocesado"
          onMouseOver={handleMouseOverPostprocesado}
          onMouseOut={handleMouseOutPostprocesado}
        />
      </div>
      </div>
    </div>
  );
};

export default ContainerBoxes;