import { Link } from "react-router-dom"
import React, { useState } from "react";
import "./Foot.css"
import isotipo from "../../Images/Isotipo.png"
import empezaracrear from "../../Images/BotonEmpezaraCrear.png"
import empezaracrearOn from "../../Images/BotonEmpezaracrearON.png"
const Foot = () => {
    const [hovered, setHovered] = useState(false);
    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };
    return (
        <div className="mainContainer">
            <div className="crear">
                <div className="espacio_empezarcrear">
                    <Link to="/sigin" className="empezarcrear" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <img src={hovered ? empezaracrearOn : empezaracrear} />
                    </Link>
                </div>
            </div>
            <div className="linksfooter">
                <Link to="/" style={{ color: 'white', textDecoration: 'none', fontSize: '20px' }}>
                    <img src={isotipo} alt="Isotipo" />
                    &copy; 2023, Piknamic. Todos los derechos reservados
                </Link>
                <Link to="/" style={{ color: 'white', textDecoration: 'none', fontSize: '20px' }}>
                    Terminos de uso
                </Link>
                <Link to="/" style={{ color: 'white', textDecoration: 'none', fontSize: '20px' }}>
                    Politica de privacidad
                </Link>
            </div>
        </div>
    )
}

export default Foot