import "./BoxOne.css"
import quierocrear from "../../Images/BotonQuieroCrearOFF.png"
import BotonQuieroCrearON from "../../Images/BotonQuieroCrearON.png"
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';

const BoxOne = () => {
    const [hovered, setHovered] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const checkSession = () => {
            const userData = localStorage.getItem('userData');            
            setIsLoggedIn(userData );
        };
        // Verificar la sesión al montar el componente
        checkSession();        
    }, []);

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };
    return (
        <div className="boxOne">
            <h1 className="whitetextbig">
                Un estudio virtual de
            </h1>
            <h1 className="whitetextbig">
                fotografía para producto.
            </h1>            
            <br></br>
            <p className="whitetext">Con Piknamic crea increíbles imágenes de
            </p>
            <p className="whitetext">
                producto en tiempo real de manera fácil</p>
            <p className="whitetext">
                y divertida</p>
            <br></br>
            <div className="espacio">
                <Link
                    to={isLoggedIn ? "/home" : "/sigin"}
                    className="quierocrear"
                    onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <img src={hovered ? BotonQuieroCrearON : quierocrear} />
                </Link>
            </div>
        </div>
    )
}

export default BoxOne