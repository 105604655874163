import React, { Suspense, useEffect, useRef, useState} from 'react';
import { Canvas } from 'react-three-fiber';
import { OrbitControls } from '@react-three/drei';
import Loading from '../Loading/Loading';

export default function ComponentRender({ props, number }) {
  const canvasRef = useRef(null);
  const modelRef = useRef();
  const [isRotating, setIsRotating] = useState(true); 

  const handleWheel = (e) => {
    e.preventDefault();
  };

  const camera = [
    { zoom: 1.4, position: [0, 0, 6] },
    { zoom: 2.8, position: [0, 0, 6] },
    { zoom: 2.2, position: [0, 3, 6] }
  ];

  useEffect(() => {
    adjustScale();
    window.addEventListener('resize', adjustScale);

    return () => {
      window.removeEventListener('resize', adjustScale);
    };
  }, []);

  const adjustScale = () => {
    if (canvasRef.current && modelRef.current && modelRef.current.geometry) {
      const canvasSize = canvasRef.current.getBoundingClientRect();
      const modelSize = modelRef.current.geometry.boundingBox.getSize();

      const scaleToFit = Math.min(
        canvasSize.width / modelSize.x,
        canvasSize.height / modelSize.y
      );

      modelRef.current.scale.set(scaleToFit, scaleToFit, scaleToFit);
    }
  };

   const rotateModel = () => {
    if (modelRef.current && isRotating) {
      modelRef.current.rotation.y += 0.002; 
    }
    requestAnimationFrame(rotateModel);
  };

  useEffect(() => {
    rotateModel();
  }, [isRotating]);

  const handleCanvasClick = () => {
    setIsRotating((prevState) => !prevState); 
  };

  return (
    <div
      style={{ width: '100%', height: '100%' }}
      onWheel={handleWheel}
      onClick={handleCanvasClick} 
      ref={(el) => {
        if (el) {
          el.addEventListener('wheel', handleWheel, { passive: false });
        }
      }}
    >
      <Canvas
        camera={camera[number]}
        style={{ display: 'block', width: '100%', height: '100%' }}
        ref={(canvas) => {
          canvasRef.current = canvas;
        }}
      >
        <ambientLight intensity={0.5} />
        <pointLight position={[35, 35, 15]} intensity={0.8} />
        <pointLight position={[-35, 35, 15]} intensity={0.8} />
        <pointLight position={[0, 0, -35]} intensity={0.8} />
        <pointLight position={[0, -35, 0]} intensity={0.8} />
        <Suspense fallback={<Loading />}>
          <primitive object={props} ref={modelRef} />
        </Suspense>
        <OrbitControls enableZoom={false} />
      </Canvas>
    </div>
  );
}
// const restoreContext = () =>{
//   const webglContext = canvasRef.current && canvasRef.current.getContext("webgl");
  
//   if (webglContext.isContextLost())
//   webglContext.getExtension('WEBGL_lose_context')?.restoreContext();
//   // loadModels();
// }

// const deletContext = ()=>{
//   const webglContext = canvasRef.current && canvasRef.current.getContext("webgl");
//   webglContext.getExtension('WEBGL_lose_context')?.loseContext();
// }

// useEffect para eliminar el contexto al montar el componente
// useEffect(() => {
//   restoreContext()
//   return ()=>{
//     deletContext();
//   }
// }, []);