import React from "react";
import "./Landing.css";
import CarouselOne from "../../components/CarouselOne/CarouselOne";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CarouselTwo from "../../components/CarouselTwo/CarouselTwo";
import Foot from "../../components/Foot/Foot";
import Header from "../../components/Header/Header";
import ContainerBoxes from "../../components/ContainerBoxes/ContainerBoxes";


const Landing = () => {


  return (
    <div className="landing-container">
      <div className="header">
        <Header />
      </div>


      <div className="container1">
        <h1 className="black-text">
          <span className="purpletext">Crea increíbles imágenes de producto</span> <span className="orangetext">en tiempo real</span>
        </h1>
        <br></br>
        <p>
          Con Piknamic tienes un sin fin de posibilidades creativas que te
          ayudaran a
        </p>
        <p>mostrar tus proyectos con el atractivo que se merecen ¿Facil,eficaz y divertido!</p>
        <br></br>
        <CarouselOne />
      </div>

      <div className="container1">
        <h1 className="black-text">
          <span className="purpletext">  ¿Por qué </span> <span className="orangetext">Piknamic?</span>
        </h1>
        <br></br>
        <p>
          No necesitas ser un pro en 3D o edición de imágenes. Te ofrecemos herramientas
        </p>
        <p>sencillas y fáciles de usar para que todo lo que quieras crear esté a tu alcance.</p>
        <br></br>
        <ContainerBoxes />
      </div>

      <div className="carousel2">
        <div className="container1">
          <h1 className="black-text">
            <span className="purpletext">  ¡Una plataforma, </span> <span className="orangetext">muchos propósitos! </span>
          </h1>
          <br></br>
          <p>
            Muestra tus proyectos para tus clientes, portafolio, propuestas, redes sociales y mucho más.
          </p>
          <br></br>
        </div>
        <CarouselTwo />
      </div>

      <footer className="footer">
        <Foot />
      </footer>
    </div>
  );
};

export default Landing;