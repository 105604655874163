import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./NavBarResponsive.css";
import iniciarsesionV from "../../Images/Boton Inicia Sesionmdpi.png";
import BotonIniciaSesionON from "../../Images/BotonIniciaSesionON.png";
import registrate from "../../Images/Boton Registratemdpi.png";
import BotonRegistrateON from "../../Images/BotonRegistrateON.png"
import logo from "../../Images/Imagotipo horizontal.png";
import pp from "../../Images/PP.png";
import Cookies from 'js-cookie';
import Isotipo from "../../Images/Isotipo.png";
import MHIniciaSesionOFF from "../../Images/MH-InicioSesion-OFF.png"
import MHIniciaSesionON from "../../Images/MH-InicioSesion-ON.png"
import MHInspiracionOFF from "../../Images/MH-Inspiracion-OFF.png"
import MHInspiracionON from "../../Images/MH-Inspiracion-ON.png"
import MHNosotrosOFF from "../../Images/MH-Nosotros-OFF.png"
import MHNosotrosON from "../../Images/MH-Nosotros-ON.png"
import MHRegistrateOFF from "../../Images/MH-Registrate-OFF.png"
import MHRegistrateON from "../../Images/MH-Registrate-ON.png"
import MHTarifasOFF from "../../Images/MH-Tarifas-OFF.png"
import MHTarifasON from "../../Images/MH-Tarifas-ON.png"
import MHPlanOFF from "../../Images/MH-Plan OFF.png"
import MHPlanON from "../../Images/MH-Plan ON.png"
import MNCerrarSesionOFF from "../../Images/MN-CerrarSesion-OFF.png"
import MNCerrarSesionON from "../../Images/MN-CerrarSesion-ON.png"
import MHMiEstudioOFF from "../../Images/MH-MiEstudio-OFF.png"
import MHMiEstudioON from "../../Images/MH-MiEstudio-ON.png"
import MHCerrarSesionOFF from "../../Images/MH-CerrarSesion-OFF.png"
import MHCerrarSesionON from "../../Images/MH-CerrarSesion-ON.png"
import MHConfiguracionON from "../../Images/MH-Config-ON.png"
import MHConfiguracionOFF from "../../Images/MH-Config-OFF.png"

const NavBarResponsive = () => {
  const [navVisible, setNavVisible] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(!!Cookies.get('userData'));
  const [logoutVisible, setLogoutVisible] = useState(false);
  const [logoutVisibleR, setLogoutVisibleR] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [hoveredR, setHoveredR] = useState(false);
  const [hoveredN, setHoveredN] = useState(false);
  const [hoveredI, setHoveredI] = useState(false);
  const [hoveredT, setHoveredT] = useState(false);
  const [hoveredP, setHoveredP] = useState(false);
  const [iniciarSesionImage, setIniciarSesionImage] = useState(BotonIniciaSesionON);
  const [iniciarsesion, setIniciarsesion] = useState(iniciarsesionV);
  const [imagenRegistrateOn, setImagenRegistrateOn] = useState(BotonRegistrateON);
  const [imagenRegistrateOff, setImagenRegistrateOff] = useState(registrate);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleMouseEnterR = () => {
    setHoveredR(true);
  };

  const handleMouseLeaveR = () => {
    setHoveredR(false);
  };

  const handleMouseEnterN = () => {
    setHoveredN(true);
  };

  const handleMouseLeaveN = () => {
    setHoveredN(false);
  };

  const handleMouseEnterI = () => {
    setHoveredI(true);
  };

  const handleMouseLeaveI = () => {
    setHoveredI(false);
  };

  const handleMouseEnterT = () => {
    setHoveredT(true);
  };

  const handleMouseLeaveT = () => {
    setHoveredT(false);
  };

  const handleMouseEnterP = () => {
    setHoveredP(true);
  };

  const handleMouseLeaveP = () => {
    setHoveredP(false);
  };

  const navRef = useRef(null);
  const responsiveUserRef = useRef(null);

  const toggleNav = () => {
    setNavVisible((prevNavVisible) => !prevNavVisible);
  };

  const handleLogout = () => {
    // Eliminar cookies y actualizar estado
    Cookies.remove('userData');
    Cookies.remove('projects');
    Cookies.remove('success');
    localStorage.clear();
    setIsLoggedIn(false);
    window.location.reload();
  };

  useEffect(() => {

    const handleResize = () => {
      if (window.innerWidth < 1100) {
        setIniciarSesionImage(MHIniciaSesionON);
        setIniciarsesion(MHIniciaSesionOFF);
        setImagenRegistrateOff(MHRegistrateOFF);
        setImagenRegistrateOn(MHRegistrateON);
      } else {
        setIniciarSesionImage(BotonIniciaSesionON);
        setIniciarsesion(iniciarsesionV);
        setImagenRegistrateOff(registrate);
        setImagenRegistrateOn(BotonRegistrateON);
      }
    };

    handleResize(); // Llama a la función al cargar la página

    window.addEventListener('resize', handleResize);

    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setNavVisible(false);
        setLogoutVisible(false);
      }
    };

    const handleLogoutRClickOutside = (event) => {
      if (responsiveUserRef.current && !responsiveUserRef.current.contains(event.target)) {
        setLogoutVisibleR(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("mousedown", handleLogoutRClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("mousedown", handleLogoutRClickOutside);
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  return (
    <header>
      <img className={`logo ${navVisible ? "visible" : ""}`} src={logo} alt="Logo" />
      <img className={`isotipo ${navVisible ? "visible" : ""}`} src={Isotipo} alt="Isotipo" />

      <button id="abrir" className="abrir-menu" onClick={toggleNav}>
        {isLoggedIn ?
          <h3 className="user-info-responsive">
            <img className="ppr" src={pp} alt="pp" onClick={() => setLogoutVisibleR(!logoutVisibleR)} />{" "}
            {Cookies.get('userData') && JSON.parse(Cookies.get('userData')).name}
          </h3> :
          <i className="bi bi-list"></i>}

      </button>

      <nav className={`nav ${navVisible ? "visible" : ""}`} id="nav" ref={navRef}>
        <button id="cerrar" className="cerrar-menu" onClick={toggleNav}>
          {isLoggedIn ?
            <h3 className="user-info-responsive">
              {/* <img className="ppr" src={pp} alt="pp" onClick={() => setLogoutVisibleR(!logoutVisibleR)} /> */}
            </h3> :
            <i className="bi bi-list"></i>}
        </button>

        <ul className="nav-list">
          <li><Link to="/1" onMouseEnter={handleMouseEnterN} onMouseLeave={handleMouseLeaveN}>
            {window.innerWidth < 1100 ? <img src={hoveredN ? MHNosotrosON : MHNosotrosOFF} alt="Nosotros" /> : <a href="1" className="nav-text">Nosotros</a>}
          </Link></li>

          <li><Link to="/2" onMouseEnter={handleMouseEnterI} onMouseLeave={handleMouseLeaveI}>
            {window.innerWidth < 1100 ? <img src={hoveredI ? MHInspiracionON : MHInspiracionOFF} alt="Inspiración" /> : <a href="2" className="nav-text">Inspiración</a>}
          </Link></li>

          {isLoggedIn ? (
            <>
              <li></li>
              <li><Link to="/4" onMouseEnter={handleMouseEnterP} onMouseLeave={handleMouseLeaveP}>
                {window.innerWidth < 1100 ? <img src={hoveredP ? MHPlanON : MHPlanOFF} alt="Tu Plan" /> : <a href="4" className="nav-text">Tu plan</a>}
              </Link></li>

              <li>
                <Link to="/" onClick={handleLogout} onMouseEnter={handleMouseEnterR} onMouseLeave={handleMouseLeaveR}>
                  {window.innerWidth < 1100 ? <img src={hoveredR ? MHCerrarSesionON : MHCerrarSesionOFF} alt="CerrarSesion" /> : <li></li>}

                </Link>
              </li>
              <li>
                <Link to="/home" onMouseEnter={handleMouseEnterT} onMouseLeave={handleMouseLeaveT}>
                  {window.innerWidth < 1100 ? <img src={hoveredT ? MHMiEstudioON : MHMiEstudioOFF} alt="Mi Estudio" /> : <li></li>}
                </Link>
              </li>
              <li>
                <Link to="/configuracion" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                  {window.innerWidth < 1100 ? <img src={hovered ? MHConfiguracionON : MHConfiguracionOFF} alt="Configuración" /> : <li></li>}
                </Link>
              </li>



              <div className="userOptions">
                {isLoggedIn ? (
                  <>
                    <h3 className="user-info">
                      <img className="pp" src={pp} alt="pp" onClick={() => setLogoutVisible(!logoutVisible)} />{" "}
                      {Cookies.get('userData') && JSON.parse(Cookies.get('userData')).name}
                    </h3>

                    {logoutVisible && isLoggedIn && (
                      <div className="lasopciones">
                        <ul>
                        <li>
                            <Link to="/home" onMouseEnter={handleMouseEnterT} onMouseLeave={handleMouseLeaveT}>
                              <img src={hoveredT ? MHMiEstudioON : MHMiEstudioOFF} alt="Mi Estudio" />
                            </Link>
                          </li>
                          <li>
                            <Link to="/configuracion" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                              <img src={hovered ? MHConfiguracionON : MHConfiguracionOFF} alt="Configuración" />
                            </Link>
                          </li>                         
                          <li>
                            <Link to="/" onClick={handleLogout} onMouseEnter={handleMouseEnterR} onMouseLeave={handleMouseLeaveR}>
                              <img src={hoveredR ? MNCerrarSesionON : MNCerrarSesionOFF} alt="CerrarSesion" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    )}

                  </>
                ) : ""}
              </div>

            </>
          ) : (
            <>
              <div className="registerLoginBotton">
                <li><Link to="/3" onMouseEnter={handleMouseEnterT} onMouseLeave={handleMouseLeaveT}>
                  {window.innerWidth < 1100 ? <img src={hoveredT ? MHTarifasON : MHTarifasOFF} alt="Tarifas" /> : <a href="3" className="nav-text">Tarifas</a>}
                </Link></li>
                <li>
                  <Link to="/sigin" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <img src={hovered ? iniciarSesionImage : iniciarsesion} alt="Iniciar sesión" />
                  </Link>
                </li>
                <li>
                  <Link to="/register" onMouseEnter={handleMouseEnterR} onMouseLeave={handleMouseLeaveR}>
                    <img src={hoveredR ? imagenRegistrateOn : imagenRegistrateOff} alt="Registrarse" />
                  </Link>
                </li>
              </div>
            </>
          )}
        </ul>
      </nav>
    </header>

  );
};

export default NavBarResponsive;

{/* <div className="userOptionsResponsive"
        ref={responsiveUserRef}
      >
        {isLoggedIn ? (
          <>
            <h3 className="user-info-responsive">
              <img className="ppr" src={pp} alt="pp" onClick={() => setLogoutVisibleR(!logoutVisibleR)} />{" "}
              {Cookies.get('userData') && JSON.parse(Cookies.get('userData')).name}
            </h3>

            {logoutVisibleR && isLoggedIn && (
              <div className="lasopcionesR">
                <ul>
                  <img className="pp" src={pp} alt="pp" onClick={() => setLogoutVisibleR(!logoutVisibleR)} />
                  <li>
                    <Link to="/configuracion" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                      <img src={hovered ? MNConfiguracionON : MNConfiguracionOFF} alt="Configuración" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/" onClick={handleLogout} onMouseEnter={handleMouseEnterR} onMouseLeave={handleMouseLeaveR}>
                      <img src={hoveredR ? MNCerrarSesionON : MNCerrarSesionOFF} alt="CerrarSesion" />
                    </Link>
                  </li>
                </ul>
              </div>
            )}

          </>
        ) : ""}
      </div> */}
