import { Link } from "react-router-dom"
import React, { useState } from "react";
import "./FootHome.css"
import isotipo from "../../Images/Isotipo.png"

const FootHome = () => {
    return (
        <div className="mainContainer">
            <div className="crear">
                <div className="espacio_empezarcrear">
                   
                </div>
            </div>
            <div className="linksfooter">
                <Link to="/" style={{ color: 'white', textDecoration: 'none', fontSize: '20px' }}>
                    <img src={isotipo} alt="Isotipo" />
                    &copy; 2023, Piknamic. Todos los derechos reservados
                </Link>
                <Link to="/" style={{ color: 'white', textDecoration: 'none', fontSize: '20px' }}>
                    Terminos de uso
                </Link>
                <Link to="/" style={{ color: 'white', textDecoration: 'none', fontSize: '20px' }}>
                    Politica de privacidad
                </Link>
            </div>
        </div>
    )
}

export default FootHome