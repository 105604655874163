import imagen1 from "../../Images/Banner 1.png"
import imagen2 from "../../Images/Banner 2.png"
import imagen3 from "../../Images/Banner 3.png"
import { Carousel } from "react-responsive-carousel";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./CarouselOne.css"; 

const CarouselOne = () => {
    return (
        <div className= "carouselOne">
            <Carousel autoPlay infiniteLoop showStatus={false} showThumbs={false}showArrows={true} showIndicators={true}>
                    <div>
                        <img src={imagen1} alt="Imagen 1" />
                    </div>
                    <div>
                        <img src={imagen2} alt="Imagen 2" />
                    </div>
                    <div>
                        <img src={imagen3} alt="Imagen 3" />
                    </div>
                </Carousel>                  
            </div>
    )
}

export default CarouselOne