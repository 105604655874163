import React, { useState } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { webGLContext } from "./WebGlContext";

export default function WebGlProvider({ children }) {
  // Estado para los modelos GLTF y su carga
  const [img3D1, setImg3D1] = useState(null);
  const [img3D2, setImg3D2] = useState(null);
  const [img3D3, setImg3D3] = useState(null);

  // Función para cargar un modelo GLTF
  const loadGLTF = async (url) => {
    const loader = new GLTFLoader();
    return new Promise((resolve, reject) => {
      loader.load(url, (gltf) => {
        resolve(gltf.scene);
      }, undefined, reject);
    });
  };

  // Función para cargar un modelo GLTF de forma asincrónica
  const loadModel = async (url, setImg) => {
    try {
      const gltf = await loadGLTF(url);
      setImg(gltf);
      console.log(`Modelo cargado: ${url}`);
    } catch (error) {
      console.error(`Error al cargar modelo: ${url}`, error);
    }
  };

  const loadModels = async () =>{
    !img3D1 && await loadModel("/tubos_galletas.gltf", setImg3D1);
    !img3D2 && await loadModel("/lata.gltf", setImg3D2);
    !img3D3 && await loadModel("/caja.gltf", setImg3D3);
    return true
  }

  // Cargar los modelos en useEffect
  // useEffect(() => {
  //   if(!img3D1&&!img3D2&&!img3D3) loadModels()
  // }, []);


  return (
    <webGLContext.Provider
      value={{
        img3D1,
        img3D2,
        img3D3,
        loadModels,
      }}
    >
      {children}
    </webGLContext.Provider>
  );
}