import React, { useState, useEffect } from "react";
import CryptoJS from 'crypto-js';
import "./HeaderHome.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NavBarHome from "../NavBarHome/NavBarHome";
import CrearProyectoNuevoOFF from "../../Images/Boton crear proyecto OFF.png"
import CrearProyectoNuevoON from "../../Images/Crear un proyecto nuevo ON.png"
import Cookies from 'js-cookie';

const HeaderHome = () => {
  const [hovered, setHovered] = useState(false);
  const [userDataCifrado, setUserDataCifrado] = useState("");

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  useEffect(() => {
    const userData = localStorage.getItem('userData');
    const secretKey = 'P1kn4m1c*2023';
    const userDataCryp = CryptoJS.AES.encrypt(userData, secretKey).toString();

    setUserDataCifrado(userDataCryp);

  }, [])
  return (
    <div>
      <div className="header-container-home">
        <div className="navbar">
          <NavBarHome />
        </div>
        <div className="text">
          <h1 className="whitetextbig">¡Bienvenido de vuelta,{" "}
            {Cookies.get('userData') && JSON.parse(Cookies.get('userData')).name}
            !</h1>
          <h3 className="whitetext">¿Ya sabes lo que vas a crear hoy?</h3>
          <div className="espacio-crearproyectonuevo">
            <a            
             href={`https://editor.piknamic.com?userData=${encodeURIComponent(userDataCifrado)}`} 
              className="quierocrear" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}              
              rel="noopener noreferrer"  // Añadir por seguridad
              
              >
              <img src={hovered ? CrearProyectoNuevoON : CrearProyectoNuevoOFF} alt="proyectoNuevo" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderHome;