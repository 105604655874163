import React, { useState } from "react";
import { withRouter, Link } from "react-router-dom";
import "./PasswordRecovery.css";
import BotonCambiarON from "./Images/BotonCambiarON.png";
import BotonCambiarOFF from "./Images/BotonCambiarOFF.png";
import BotonListoON from "./Images/BotonListoON.png"
import BotonListoOFF from "./Images/BotonListoOFF.png"


const PasswordRecovery = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [hoveredI, setHoveredI] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);




  const handleMouseEnterI = () => {
    setHoveredI(true);
  };

  const handleMouseLeaveI = () => {
    setHoveredI(false);
  };



  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validar el formato del correo electrónico
    if (!validateEmail()) {
      setErrorMessage("Por favor, introduce un correo electrónico válido.");
      setSuccessMessage("");
      return;
    }

    // Realizar la solicitud a la API
    try {
      const response = await fetch("https://editor.piknamic.com/api/password/email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setSuccessMessage("Hemos enviado un enlace de recuperacion al correo que tienes registrado con esta cuenta");
        setErrorMessage("");
        setFormSubmitted(true);
      } else {
        setErrorMessage("Hubo un problema al enviar el correo electrónico. Inténtalo de nuevo.");
        setSuccessMessage("");
      }
    } catch (error) {
      console.error("Error al realizar la solicitud a la API:", error);
      setErrorMessage("Hubo un problema al enviar el correo electrónico. Inténtalo de nuevo.");
      setSuccessMessage("");
    }
  };

  return (
    <div className="passwordRecoveryForm">
      <div className="form">
        {formSubmitted ? (
          <div className="success">
            <div className="success-message">{successMessage}</div>
            <br></br>
            <Link
              to="/"
              onMouseEnter={handleMouseEnterI} onMouseLeave={handleMouseLeaveI}
            >
              {<img src={hoveredI ? BotonListoON : BotonListoOFF} alt="Listo" />}
            </Link>

          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Correo"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="error-message">{errorMessage}</div>
            <div className="submit-button">
              <button type="submit" onMouseEnter={handleMouseEnterI} onMouseLeave={handleMouseLeaveI}>
                {<img src={hoveredI ? BotonCambiarON : BotonCambiarOFF} alt="Inicio" />}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default withRouter(PasswordRecovery);