import React, { useState } from "react";
import "./Home.css";
import FootHome from "../../components/Foot Home/FootHome";
import HeaderHome from "../../components/Header Home/HeaderHome";
import UserBar from "../../components/UserBarHome/UserBarHome";
import Projects from "../../components/Projects/Projects";

const Home = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  return (
    <div>
      <div className="headerHome">
        <HeaderHome />
      </div>
      <div className="cuerpo">
        <div className="userBar">
          <UserBar />
        </div>
        <div className="proyects">
          <Projects searchTerm={searchTerm} />
        </div>        
      </div> 
      <div>
          <FootHome/>
        </div>    
    </div>
  );
};

export default Home;