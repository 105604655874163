import React, { useState, useEffect } from "react";
import "./Projects.css";
import Slider from "react-slick";
import CryptoJS from 'crypto-js';
import preview from "./Image/Fotoproyecto.png"

const Projects = () => {
    const [projects, setProjects] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [userDataCifrado, setUserDataCifrado] = useState("");


    useEffect(() => {
        const storedProjects = localStorage.getItem('projects');
        const userData = localStorage.getItem('userData');
        const secretKey = 'P1kn4m1c*2023';
        const userDataCifrado = CryptoJS.AES.encrypt(userData, secretKey).toString();

        setUserDataCifrado(userDataCifrado);

        if (storedProjects) {
            setProjects(JSON.parse(storedProjects));
        }
    }, []);

    const filteredProjects = projects.filter(project =>
        project.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const settings3 = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const settings4 = {
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1
    };

    return (
        <div className="containerProjects">
            <div className="search-bar">
                <i className="bi bi-search"></i>
                <input
                    type="text"
                    placeholder="Busca un proyecto"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            <h1 className="tusproyectos">Tus proyectos</h1>
            {filteredProjects.length === 0 ? (
                <div className="no-projects">
                    <h1>Aún no tienes proyectos</h1>
                    <i className="bi bi-plus-circle"></i>
                </div>
            ) : (
                <div className="renderProjects">
                    {filteredProjects.length <= 3 ? (
                        <Slider {...settings3}>
                            {filteredProjects.map((project, index) => (
                                <a
                                    key={index}
                                    href={`https://editor.piknamic.com?userData=${encodeURIComponent(userDataCifrado)}&project=${encodeURIComponent(JSON.stringify(project))}`}
                                    rel="noopener noreferrer"  // Añadir por seguridad
                                    
                                >
                                    <div className="upToThreeProjects">
                                        {project.preview ? (
                                            <img src={project.preview} alt={project.name} />

                                        ) : (
                                            <>
                                                <img src={preview} alt={project.name} />
                                                <p className="projectName">
                                                    {project.name}
                                                </p>
                                            </>

                                        )}
                                    </div>
                                </a>
                            ))}
                        </Slider>
                    ) : (
                        <>
                            <Slider {...settings4}>
                                {filteredProjects.slice(0, Math.ceil(filteredProjects.length / 2)).map((project, index) => (
                                    <a
                                        key={index}
                                        href={`https://editor.piknamic.com?userData=${encodeURIComponent(userDataCifrado)}&project=${encodeURIComponent(JSON.stringify(project))}`}
                                        rel="noopener noreferrer"  // Añadir por seguridad
                                    >
                                        <div>
                                            {project.preview ? (
                                                <img className="userProjectImage" src={project.preview} alt={project.name} />
                                            ) : (
                                                <>
                                                    <img src={preview} alt={project.name} />
                                                    <p className="projectName" >{project.name}</p>
                                                </>
                                            )}
                                        </div>
                                    </a>
                                ))}
                            </Slider>
                            <Slider {...settings4}>
                                {filteredProjects.slice(Math.ceil(filteredProjects.length / 2)).map((project, index) => (
                                    <a
                                        key={index}
                                        href={`https://editor.piknamic.com?userData=${encodeURIComponent(userDataCifrado)}&project=${JSON.stringify(project)}`}
                                        rel="noopener noreferrer"  // Añadir por seguridad
                                    >
                                        <div>
                                            {project.preview ? (
                                                <img className="userProjectImage" src={project.preview} alt={project.name} />
                                            ) : (
                                                <>
                                                    <img src={preview} alt={project.name} />
                                                    <p className="projectName">{project.name}</p>
                                                </>
                                                
                                            )}
                                        </div>
                                    </a>
                                ))}
                            </Slider>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default Projects;