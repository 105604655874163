import React, { useContext, useEffect, useState, useRef } from "react";
import { webGLContext } from "../../Context/WebGlContext";
import ComponentRender from "../Imag3D/ComponentRender1";
import Loading from "../Loading/Loading";
import "font-awesome/css/font-awesome.css";
import "./Carousel3D.css";

const Carousel3D = () => {
  const [load, setLoad] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const isPaused = useRef(false);
  const intervalRef = useRef(null);

  const { loadModels, img3D1, img3D2, img3D3 } = useContext(webGLContext);
  const items = [img3D1, img3D2, img3D3];

  const nextItem = () => {
    setCurrentIndex((prevIndex) => (prevIndex === items.length - 1 ? 0 : prevIndex + 1));
    console.log("next");
  };

  const prevItem = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? items.length - 1 : prevIndex - 1));
    console.log("prev");
  };

  const startInterval = () => {
    intervalRef.current = setInterval(() => {
      if (!isPaused.current) {
        nextItem();
      }
    }, 7000); 
  };

  const stopInterval = () => {
    clearInterval(intervalRef.current);
  };

  useEffect(() => {
    const fetchData = async () => {
      (!img3D1 && !img3D2 && !img3D3) && (await loadModels());
      setLoad(true);
    };
    fetchData();
  }, []);

  const handlePaused = () => {
    if(!isPaused.current){
      isPaused.current = true;
      stopInterval(); 
      console.log("no");
    } 
  };

  const handleStart = () => {
    if(isPaused.current){
      isPaused.current = false;
      startInterval(); 
      console.log("si");
    } 
  };

  useEffect(() => {
    document.addEventListener("mouseup", handleStart);
    document.addEventListener("touchend", handleStart);
    startInterval();
    return () => {
      document.removeEventListener("mouseUp", handleStart);
      document.removeEventListener("mouseUp", handleStart);
      stopInterval();
    };
  }, []);

  return (
    <div className="carousel-container" 
    onMouseDown={handlePaused}
    onTouchStart={handlePaused}
    onMouseUp={handleStart}
    onTouchEnd={handleStart} >
        {load ? (
        <>
        <button className="prev-button" onClick={prevItem}>
            <i className="fa fa-chevron-left"></i>
        </button>
        <div className="carousel">
          {items.map((img, i) => (
            <div className={`component-render ${i === currentIndex ? "active" : ""}`} key={i}>
              <ComponentRender props={img} number={i} />
            </div>
          ))}
        </div>
        <button className="next-button" onClick={nextItem}>
            <i className="fa fa-chevron-right"></i>
        </button>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Carousel3D;