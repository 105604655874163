import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./NavBarHome.css";
import logo from "../../Images/Imagotipo horizontal.png";
import Isotipo from "../../Images/Isotipo.png";




const NavBarHome = () => {
  const [navVisible, setNavVisible] = useState(false);  
  return (
    <header>
      <Link to="/">
      <img className={`logo ${navVisible ? "visible" : ""}`} src={logo} alt="Logo" />
      <img className={`isotipo ${navVisible ? "visible" : ""}`} src={Isotipo} alt="Isotipo" />
      </Link>
    </header>

  );
};

export default NavBarHome;

